@import "~antd/lib/style/themes/default.less";
@import "~antd/dist/antd.less";

@import "./styles/overrides.less"; // variables to override above
@import "./styles/utilities.less";
@import "./fonts/SGTJHonO/styles.css";

// CSS variable
:root {
  /* Colors: */
  --primary-color: #7e00fd;
  --everglade-main: #254234;
  --orange-main: #fcc300;
  --orange-dark: #816900;
  --success-color: #00de8d;
  --link-color: #7e00fd;
  --error-color: #f31d1d;
  --text-color: #102548;
  --text-color-dark: rgba(46, 46, 46, 0.8);
  --text-light: #ffffff;
  --text-dark: #080a25;
  --text-accent: rgba(46, 46, 46, 0.6);
  --text-ghost: rgba(46, 46, 46, 0.4);
  --background: #ffffff;
  --primary-surface: #ffffff;
  --secondary-surface: #fafafa;

  /* font */
  --inter: "Inter", sans-serif;
  --teko: "Teko", sans-serif;
  --SGT: "SGT Jhon O", sans-serif;
  --rubik: "Rubik", sans-serif;
  --poppins: "Poppins", sans-serif;

  /* Sizes: */
  --container-width: 1095px;
  --container-lg-width: 1184px;
  --header-height: 72px;
  --navigator-height: 72px;
  --footer-height: 411px;
  --banner-height: 306px;
  --primary-navbar-height: 97px;
  --border-radius-base: 4px;
  --text-list-padding: 35px;
}

html {
  scroll-behavior: smooth;
  font-family: var(--rubik);
}

html,
body,
* {
  word-break: break-word;
}

p {
  text-rendering: optimizeLegibility;
}

h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 1rem;
  letter-spacing: 0.02em;
}

h1 {
  font-family: var(--poppins);
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 47px;

  color: var(--text-dark);
  margin-bottom: 32px;
}

h2 {
  font-family: var(--poppins);
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 47px;

  color: var(--text-dark);
}

h3 {
  font-family: var(--poppins);
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 31px;

  color: var(--text-dark);
}

pre {
  background: #ffffff;
  padding: 1em;
  margin: 0.5em 0;
  overflow: auto;
  border-radius: 10px;
}

a.active {
  color: var(--link-color);
}

// .ant-input-number-handler-wrap {
//   visibility: hidden;
// }

.alert {
  background: #f6f6f6;
  border-radius: 4px;
  font-size: 14px;
  line-height: 21px;
  color: rgba(46, 46, 46, 0.8);
  border: none !important;
  padding: 16px !important;
}
