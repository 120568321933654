.logo {
  font-family: var(--SGT);
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 29px;
  letter-spacing: 0.4em;
  width: fit-content;
}

.logo img {
  height: 65px;
}

.light {
  color: #ffffff;
}
.dark {
  color: #000000;
}
