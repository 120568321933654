.root {
  border-radius: 10px;
}

.root :global .ant-modal-content {
  border-radius: 10px;
  overflow: hidden;
}
.root :global .ant-modal-header {
  padding: 16px;
}

.menu {
  padding: 16px;
  list-style: none;
}

.menu li {
  padding: 16px;
  cursor: pointer;
  background-color: #f6f6f6;
  margin-top: 13px;
  border-radius: 16px;
  color: var(--text-color);
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  outline: 1px solid #e4e5e7;
  transition: all 0.3s;
}

.menu li:hover {
  outline: 1px solid var(--link-color);
}

.menu li img {
  height: 24px;
  width: 24px;
}

.menu li p {
  margin-bottom: 0;
}
