.surface {
  border-radius: var(--border-radius-base);
}

.primary {
  background-color: var(--primary-surface);
  box-shadow: 0px 12px 45px rgba(8, 10, 37, 0.04);
}

.secondary {
  background-color: var(--secondary-surface);
}

.hoverable {
  cursor: pointer;
  transition: box-shadow 0.3s, border-color 0.3s;
}

.hoverable:hover {
  border-color: #b3b3b3;
  box-shadow: 0 0 4px 0px rgb(0 0 0 / 40%);
}

.bordered {
  border: 1px solid rgba(46, 46, 46, 0.15);
}

.rounded_1 {
  border-radius: 8px;
}

.rounded_2 {
  border-radius: 12px;
}
