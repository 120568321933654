/*Utilities css*/
.container {
  max-width: var(--container-width);
  margin: 0 auto;
  padding-right: 15px;
  padding-left: 15px;
}

.container-lg {
  max-width: var(--container-lg-width);
  margin: 0 auto;
  padding-right: 15px;
  padding-left: 15px;
}

.h-100 {
  height: 100%;
}

.h-40 {
  height: 40px !important;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.m-0 {
  margin: 0;
}

.p-0 {
  padding: 0;
}
.pr-0 {
  padding-right: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.pl-16 {
  padding-left: 16px !important;
}
.pl-24 {
  padding-left: 24px !important;
}

.p-24 {
  padding: 24px !important;
}

.l-s-2 {
  letter-spacing: 2px;
}

.mx-8 {
  margin-left: 8px;
  margin-right: 8px;
}

.mr-8 {
  margin-right: 8px;
}
.ml-72 {
  margin-left: 72px;
}

.my-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mt-24 {
  margin-top: 24px !important;
}
.mt-32 {
  margin-top: 32px !important;
}
.mb-24 {
  margin-bottom: 24px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mt-62 {
  margin-top: 62px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.inter {
  font-family: var(--inter);
}

.poppins {
  font-family: var(--poppins);
}

.rubik {
  font-family: var(--rubik);
}

.teko {
  font-family: var(--teko);
}
.SGT {
  font-family: var(--SGT);
}

.primary {
  color: var(--primary-color) !important;
}

.secondary {
  color: var(--text-dark) !important;
}

.default-light {
  color: #4b587c;
}

.success {
  color: var(--success-color) !important;
}
.success-2 {
  color: #00a711 !important;
}

.info {
  color: var(--link-color) !important;
}

.error {
  color: var(--error-color) !important;
}

.disabled {
  color: #788686 !important;
}

.default {
  color: var(--text-color) !important;
}

.default-8 {
  color: var(--text-color) !important;
  opacity: 0.8;
}

.default-6 {
  color: var(--text-color) !important;
  opacity: 0.6;
}
.border {
  border: 1px solid #ececec;
}
.border-bottom {
  border-bottom: 1px solid #ececec;
}
.divider {
  border-color: rgba(115, 137, 149, 0.2);
}

.tint {
  color: #080a25;
}

.tint-2 {
  color: rgba(8, 10, 37, 0.8);
}

.tint-3 {
  color: rgba(8, 10, 37, 0.6);
}

.black {
  color: #000000 !important;
}

.white {
  color: #ffffff !important;
}

.ghost {
  color: var(--text-ghost);
}

.orange {
  color: var(--orange-main) !important;
}

.gray {
  color: #828282;
}

.accent {
  color: var(--text-accent);
}
.link,
.link:hover {
  color: #7e00fd;
}

.default-dark {
  color: var(--text-color-dark) !important;
}

.underline {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding-bottom: 2px;
}

.underline-hover:hover {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.cursor {
  cursor: pointer;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.justify {
  text-align: justify;
}

.italic {
  font-style: italic;
}

.pre-line {
  white-space: pre-line;
}

.f-column {
  flex-direction: column;
}

.f {
  display: flex;
}

.f-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.f-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.a-start {
  align-items: flex-start;
}

.a-end {
  align-items: flex-end;
}
.a-center {
  align-items: center;
}

.f-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.f-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.no-select {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;
}

.cursor-default {
  cursor: default;
}

.relative {
  position: relative;
}

.uppercase {
  text-transform: uppercase;
}

/* Font global */
.bolder {
  font-weight: 900 !important;
}

.bold {
  font-weight: 700 !important;
}

.semi-bold {
  font-weight: 600 !important;
}

.medium {
  font-weight: 500 !important;
}

.regular {
  font-weight: 400 !important;
}

.light {
  font-weight: 200 !important;
}

.normal {
  font-weight: normal !important;
}

/* Text size */
.text-45 {
  font-size: 45px !important;
}

.text-36 {
  font-size: 36px !important;
}

.text-32 {
  font-size: 32px !important;
}

.text-30 {
  font-size: 30px !important;
}

.text-28 {
  font-size: 28px !important;
}

.text-26 {
  font-size: 26px !important;
}

.text-24 {
  font-size: 24px !important;
}

.text-22 {
  font-size: 22px !important;
}

.text-20 {
  font-size: 20px !important;
}

.text-18 {
  font-size: 18px !important;
}

.text-16 {
  font-size: 16px !important;
}

.text-14 {
  font-size: 14px !important;
}

.text-12 {
  font-size: 12px !important;
}

.lineClamp {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  min-height: 42px;
}

.concat-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-word;
}

.break {
  word-break: break-word;
}

.nowrap {
  white-space: nowrap;
}

.zIndex {
  z-index: 2;
}

.blur-bottom:after {
  content: "";
  position: absolute;
  bottom: -50%;
  left: 0;
  height: 82px;
  width: 100%;
  z-index: 1;
  background: linear-gradient(
    360deg,
    #ffffff 57.29%,
    rgba(255, 255, 255, 0) 100%
  );
  opacity: 0.7;
}
