.content {
  padding-top: 64px;
  padding-bottom: 40px;
}

.headline,
.headline:hover {
  margin-bottom: 10px;
}
.headline {
  font-family: var(--inter);
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 100%;

  color: #ffffff;
}

.text {
  font-family: var(--poppins);
  font-style: normal;
  font-size: 18px;
  color: rgba(195, 195, 195, 0.75);
}

.text:hover {
  color: var(--orange-main);
}

.copyRightWrapper {
  width: 100%;
  color: rgba(195, 195, 195, 0.75);
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.copyRightWrapper svg {
  margin-right: 16px;
}

.copyRightWrapper * {
  color: rgba(195, 195, 195, 0.75);
}

.divider {
  width: 100%;
  height: 0px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin: 40px 0;
}

.mailInput input,
.mailInput:focus {
  background-color: #080a25;
  border-color: #ffffff !important;
  min-height: 66px;
  font-weight: 400;
  font-size: 16px;
  line-height: 156%;
  /* identical to box height, or 25px */
  letter-spacing: -0.025em;
  color: #a29d9d;
  border-radius: 0;
  font-family: var(--poppins);
}

.mailInput :global .ant-input-group-addon {
  background-color: #080a25;
  border-color: #ffffff !important;
  min-height: 66px;
  min-width: 68px;
  font-weight: 400;
  font-size: 16px;
  line-height: 156%;
  /* identical to box height, or 25px */
  letter-spacing: -0.025em;
  color: #a29d9d;
  cursor: pointer;
  border-radius: 0;
}

@media (max-width: 768px) {
  .copyRightWrapper {
    flex-direction: column-reverse;
    font-size: 16px;
  }

  .headline * {
    font-size: 18px;
  }

  .text {
    font-size: 16px;
  }
}
