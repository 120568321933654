.root {
  background-color: #ffffff;
}

.gradient {
  background: linear-gradient(
    261.01deg,
    #0cdcaa 2.1%,
    #3699f8 50.03%,
    #a750ff 100%
  );
}

.small {
  border: 1px solid var(--orange-main);
}

.middle {
  border: 3px solid var(--orange-main);
}

.large {
  border: 5px solid var(--orange-main);
}

@media screen and (max-width: 1024px) {
  /* Auto resize image on mobile */
  /* .root img {
    max-width: 100% !important;
    height: auto !important;
  } */
}
