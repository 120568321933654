/* .root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: #000000;
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
} */

.root {
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  margin-bottom: 32px;
  font-size: 36px !important;
}

.spinColor i {
  background-color: #fff !important;
}
