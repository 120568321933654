.root {
  height: auto;
  margin-right: -16px;
  margin-top: -12px;
  background: #ffffff;
  box-shadow: 0px 7px 45px rgba(8, 10, 37, 0.1);
  border-radius: 12px;
  padding: 0px;
}

.scroll {
  width: 486px;
  max-height: 418px;
  overflow-y: auto;
  overflow-x: hidden;
  border-bottom-left-radius: 12px;
  border-bottom-left-right: 12px;
}

.scroll::-webkit-scrollbar {
  width: 12px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  height: 100%;
}

.scroll::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 8px;
  border-radius: 1px solid rgba(220, 220, 220, 0.8);
  height: 100%;
  margin: 0 !important;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: rgba(146, 146, 146, 0.623);
  background-clip: padding-box;
  border: 4.3px solid transparent;
}

.head {
  display: flex;
  justify-content: space-between;
  padding: 12px 24px;
  border-bottom: 1px solid rgba(8, 10, 37, 0.1);
}

.btnBellActive {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.btnBell {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.btnBell:hover,
.btnBell:focus {
  border: unset;
}
