.root {
  font-family: var(--rubik);
  border: none !important;
  border-radius: 12px;
  transition: all 0.5s;
}
.primary,
.primary:hover,
.primary:active,
.primary:focus {
  background: linear-gradient(94.91deg, #ff9900 0.75%, #f57f20 88.93%);
  box-shadow: 0px 20px 32px rgba(255, 147, 61, 0.2);
  color: #ffffff;
}

.outlined {
  background: #ffffff;
  border: 2px solid #ff9900 !important;
  color: #080a25;
}

.outlined:hover,
.outlined:active,
.outlined:focus {
  background: linear-gradient(94.91deg, #ff9900 0.75%, #f57f20 88.93%);
  box-shadow: 0px 20px 32px rgba(255, 147, 61, 0.2);
  color: #ffffff;
}

.secondary,
.secondary:hover,
.secondary:active,
.secondary:focus {
  background: #f9f9f9;
  border: 0.5px solid rgba(8, 10, 37, 0.2) !important;
  color: #1d263a;
}

.gradient_2,
.gradient_2:hover,
.gradient_2:active,
.gradient_2:focus {
  background: linear-gradient(
    263.55deg,
    rgba(0, 222, 140, 0.3) 1.97%,
    rgba(197, 138, 255, 0.3) 100%
  );
  color: #ffffff;
}

.gradient_3,
.gradient_3:hover,
.gradient_3:active,
.gradient_3:focus {
  background: linear-gradient(
    261.01deg,
    rgba(12, 220, 170, 0.2) 2.1%,
    rgba(54, 153, 248, 0.2) 50.03%,
    rgba(167, 80, 255, 0.2) 100%
  );
  color: #1d263a;
}

.gradient,
.gradient:hover,
.gradient:active,
.gradient:focus {
  background: linear-gradient(
    261.01deg,
    #0cdcaa 2.1%,
    #3699f8 50.03%,
    #a750ff 100%
  );
  color: #ffffff;
}

.primary:hover,
.secondary:hover {
  filter: brightness(90%);
  outline: none;
}

.large {
  height: 72px;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  padding-left: 31px;
  padding-right: 31px;
}

.middle {
  height: 52px;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  padding-left: 24px;
  padding-right: 24px;
}

.none,
.none:hover,
.none:active,
.none:focus {
  background: none;
  box-shadow: none;
  color: #102548;
}

.small {
  border-radius: 8px !important;
}

.view,
.view:hover,
.view:active,
.view:focus {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  border: 1px solid #adb5bd !important;
  border-radius: 4px;
  color: #102548;
  background: none;
}

.send,
.send:hover,
.send:active,
.send:focus {
  background: #7e00fd;
  color: #ffffff;
  height: 48px;
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  margin-top: 8px;
  border-radius: 12px !important;
}
