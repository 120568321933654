.error-boundary-comp-wrapper {
  position: relative;
  display: flex;
  flex: 1;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.error-boundary-container {
  text-align: center;
  justify-content: center;
  width: 85%;
}

.title {
  display: flex;
  font-size: 1.6rem;
  align-items: center;
  justify-content: center;
}

.title strong {
  font-weight: 400;
  margin-left: 10px;
}

.error-info {
  opacity: 0.5;
  position: fixed;
  bottom: 50px;
  left: 0;
  right: 0;
  width: 90%;
  padding: 15px;
  margin: 0 auto;
  font-size: 80%;
  display: block;
  max-width: 640px;
  text-align: center;
  border-radius: 5px;
  border: 1px dashed #ccc;
}

.error-info pre {
  margin-bottom: 0;
}

.error-info code {
  line-height: 1.2;
}

.refresh-button {
  margin-top: 20px;
  display: inline-flex;
  place-items: center;
  border-radius: 5px;
}

.refresh-button span,
.refresh-button svg {
  line-height: 1;
}

.refresh-button svg {
  font-size: 120%;
  margin-right: 3px;
}
