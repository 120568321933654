// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

@theme: default;
@font-family: "rubik", sans-serif;
@primary-color: #7e00fd;
@info-color: #00a3ff;
@success-color: #00de8d;
@link-color: #7e00fd;
@error-color: #f31d1d;
@border-color-base: @primary-color;
@text-color: #102548;

@layout-color: #080a25;
@body-background: #ffffff;

// -------- Design font size -----------
@font-size-base: 16px;
@font-size-lg: @font-size-base + 4px;
@font-size-sm: 12px;

// -------- Colors -----------

@layout-body-background: @body-background;
@layout-header-background: @layout-color;
@layout-header-height: 72px;
@layout-header-padding: 0px;
@layout-header-color: #ffffff;
@layout-footer-background: @layout-color;
@layout-footer-padding: 0px;
@divider-color: rgba(115, 137, 149, 0.2);

// -------- Sizing  -----------

@btn-height-base: 36px;
@btn-height-lg: 48px;
@btn-height-sm: 32px;

@input-height-base: 36px;
@input-height-lg: 48px;
@input-height-sm: 32px;

@modal-body-padding: 0px;
@form-item-margin-bottom: 16px;

// -------- Styling Input, Radio, etc... -----------
// @btn-font-weight: 600;
@border-color-base: rgba(46, 46, 46, 0.2);
@border-radius-base: 4px;
@radio-dot-color: @link-color;
@divider-color: rgba(46, 46, 46, 0.3);

@select-item-selected-bg: @item-hover-bg;

// -------- Override using class name -----------
.ant-btn {
  color: @primary-color;
  font-size: 16px; // btn font size in design
  line-height: 0.5715;
}

.ant-btn-lg {
  font-weight: 600;
  border-radius: 12px !important;
}

.ant-btn-text {
  color: #ffffff;
}

.ant-btn-primary {
  color: #ffffff;
  border: none;
}

.ant-btn-text:hover {
  opacity: 0.5;
  color: #ffffff;
}

.ant-input {
  font-family: var(--poppins);
}

.ant-input-lg {
  padding: 10.5px 11px 10.5px 20px;
  font-size: 16px;
  border-radius: 12px;
}
.ant-input-affix-wrapper-lg {
  padding: 7.2px 11px 8.9px 20px;
}
.ant-input-prefix {
  margin-right: 12px;
}

.ant-select-lg .ant-select-selector,
.ant-input-number-lg,
.ant-input-password-large {
  border-radius: 12px !important;
}

.ant-select-lg .ant-select-selector {
  min-height: 45.19px;
}

.ant-select-selection-item {
  font-size: 16px;
}

.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  border-radius: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 30px !important;
}

// Form item

.ant-form-item-label > label {
  flex-direction: row-reverse;
  font-weight: 600;
  font-size: 16px;
}

.ant-form-item-label {
  font-family: var(--poppins);
  padding: 0px 0px 4px !important;
  line-height: 24px;
}

.ant-form-item-explain .ant-form-item-explain-error {
  line-height: 24px;
}

.hkt-recommendations-graduation {
  @media screen and (max-width: 992px) {
    width: 100%;
    flex-wrap: wrap;
    .ant-space-item,
    .ant-picker {
      width: 100%;
    }
  }
}

// Picker
.hkt-recommendations-picker {
  @media screen and (max-width: 576px) {
    .ant-space-item {
      width: 100%;
      .ant-picker {
        width: 100%;
        min-width: unset;
      }
    }
  }
}

.ant-picker-large {
  min-width: 327px;
  border-radius: 12px;
}

.ant-picker-panel-container {
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1), 0px 4px 6px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 16px 27px 27px;
}

.ant-picker-date-panel .ant-picker-content {
  width: 100%;
}

.ant-picker-date-panel .ant-picker-body {
  padding: 0;
}

.ant-picker-cell .ant-picker-cell-inner {
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
}

.ant-picker-month-panel .ant-picker-cell .ant-picker-cell-inner {
  border-radius: 5px;
}
.ant-picker-year-panel .ant-picker-cell .ant-picker-cell-inner {
  border-radius: 5px;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-radius: 50%;
}

.ant-input-group-addon {
  font-family: var(--pop);
  border-radius: 12px;
  color: rgba(8, 10, 37, 0.5);
}

.ant-picker-header {
  border: none;
}

.ant-picker-large .ant-picker-input > input {
  padding: 1.5px 9px;
  font-size: 16px;
}
.ant-picker-large .ant-picker-input > input::placeholder {
  font-size: 14px;
  color: rgba(46, 46, 46, 0.4);
}

.ant-picker-suffix {
  color: @link-color;
  font-size: 18px;
}
.ant-picker-clear {
  font-size: 18px;
}

// Radio

.ant-radio-wrapper {
  font-size: 15px !important;
}

.ant-radio-wrapper-checked {
  font-weight: 500;
  color: #2e2e2e;
  font-size: 16px !important;
}

.ant-radio-button-wrapper {
  background: rgba(8, 10, 37, 0.04);
  border-radius: 8px;
  border-color: transparent;
  border-left-color: transparent !important;
}

.ant-radio-button-wrapper-checked {
  background: #7e00fd !important;
  color: #fff !important;
}

// Input number

.ant-input-number-handler-wrap svg {
  visibility: hidden;
}

.ant-input-number-handler-wrap span .anticon-down {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #00a3ff;
}
.ant-input-number-handler-wrap span .anticon-up {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #00a3ff;
}

.ant-upload-list-item-name {
  max-width: 300px;
}
.ant-popover-buttons {
  text-align: center;
}

.control-arrow::before {
  border-left-color: #000000;
}

.thumbs {
  // overflow: auto;
  padding-left: 0;
}
.ant-tag-checkable-checked {
  background-color: #7e00fd !important;
}

.carousel.carousel-slider:hover .control-arrow {
  background: rgba(0, 0, 0, 0.2) !important;
}

.thumb {
  position: relative;
  border: 2px solid rgba(115, 137, 149, 0.4) !important;
  border-radius: 8px;
}

.thumb img {
  height: 72px;
  width: 96px;
  border-radius: 4px;
  object-fit: cover !important;
}

.thumb.selected {
  border: solid 2px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(101deg, #78e4ff, #ff48fa);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
}

.ant-form-item-required::before {
  margin-top: -8px;
  margin-left: 3px;
}

@media only screen and (max-width: 768px) {
  .ant-upload-list-item-name {
    max-width: unset;
  }
}
