.root {
  width: 312px;
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%),
    #ffffff;
  box-shadow: 0px 8px 20px rgba(211, 184, 199, 0.16);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.infoWrapper {
  background-color: #f6f6f6;
  padding: 18px 32px 11px 32px;
}

/* Arrow */
.infoWrapper::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #f6f6f6;
  position: absolute;
  top: -10px;
  right: 5px;
}

.infoWrapper h3 {
  font-family: var(--teko);
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 40px;
  margin-bottom: 2px;
}

.completeWrapper {
  margin: 0px 32px;
  padding: 24px 0px;
  border-bottom: 1px dashed #cdcece;
}

.progress :global .ant-progress-text {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.avatar {
  position: relative;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
}

.avatar::after {
  content: "";
  width: 10px;
  height: 10px;
  border: 1px solid #080a25;
  border-radius: 50%;
  background-color: var(--orange-main);
  position: absolute;
  bottom: 15px;
  right: 0px;
}

.hideDot::after {
  display: none;
}

.menu {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #5c5c5c;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 18px;
}

.menu :global .ant-menu-item-selected,
.menu :global .ant-menu-item:active {
  background-color: transparent !important;
  font-weight: 600;
}

.logoutWrapper {
  margin: 0px 32px;
  padding: 24px 0px;
  border-top: 1px dashed #cdcece;
}

.logoutWrapper button {
  padding-left: 0;
  font-family: var(--inter) !important;
}
