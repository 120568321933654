.title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  /* identical to box height, or 29px */

  /* regular black */

  color: #102548;
}

.listSetting {
  list-style: none;
  padding-left: 0;
  margin-bottom: 48px;
}

.listSetting > li {
  cursor: pointer;
  background: #ffffff;
  border: 2px solid rgba(115, 137, 149, 0.2);
  border-radius: 8px;
  height: 64px;
  line-height: 60px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  opacity: 0.8;
  margin-bottom: 8px;
  position: relative;
  transition: all 0.5s;
}

.listSetting > li:hover,
.listSetting > li.active {
  border: solid 2px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(101deg, #78e4ff, #ff48fa);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
}

.listSetting > li:active {
  font-weight: 500;
}

.listSetting > li:hover::after,
.listSetting > li.active::after {
  content: "";
  width: 100%;
  height: 100%;
  box-shadow: 0px 7px 45px rgba(0, 222, 140, 0.12);
  position: absolute;
  top: 0;
  left: 0;
}
