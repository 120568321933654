.nav {
  width: 100%;
  height: 89px;
  background: linear-gradient(
    90deg,
    #c58aff 0%,
    #00adfb 50.61%,
    #00c1ce 78.78%,
    #00de8c 100%
  );
}

.root {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navList {
  display: flex;
  justify-content: center;
  gap: 64px;
}

.navList a {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  height: 72px;
  line-height: 72px;
  color: #ffffff;
  height: 100%;
  transition: 0.2s linear;
  font-family: var(--poppins);
}

.navList a:hover {
  color: var(--orange-main);
}

.btnSignUp {
  background-color: var(--orange-main);
  text-align: center;
  color: #080a25 !important;
  font-weight: 600 !important;
  width: 130px;
  height: 47px;
  line-height: 47px !important;
  background: #ffffff;
  box-shadow: 0px 8px 16px rgba(17, 17, 17, 0.06);
  border-radius: 8px;
  align-self: center;
}

.btnSignUp:hover {
  opacity: 0.6;
}

.burger span {
  width: 24px;
  height: 1px;
  background-color: #fff;
  position: relative;
  transition: all 0.5s ease-in-out;
}

.burger span::before,
.burger span::after {
  content: "";
  width: 24px;
  height: 1px;
  background-color: #fff;
  position: absolute;
  left: 0;
}

.burger span::before {
  top: 8px;
}

.burger span::after {
  bottom: 8px;
}

.drawerMenu :global .ant-drawer-body {
  padding-left: 0;
}

.menu {
  margin-top: 32px;
  font-family: var(--poppins);
  font-weight: 500;
  font-size: 16px;
}

.userDropdown {
  position: relative;
  padding-right: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobileUser {
  padding-left: 18px;
}

.logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 960px) {
  .logo * {
    width: 150px;
  }

  .navList {
    gap: 32px;
  }
}

@media (max-width: 768px) {
  .root {
    padding: 0px 15px;
  }
}
