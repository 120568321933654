.root {
  position: relative;
  background: none !important;
  overflow-x: hidden;
}

.layoutContent {
  position: relative;
  min-height: calc(
    100vh - (var(--header-height) * 2 + var(--footer-height))
  ) !important;
}
