.item {
  width: 100%;
  min-height: 80px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
}
.item:hover {
  background: rgba(220, 220, 220, 0.2);
}

/* .item:hover {
  background: var(--background);
}
.unread {
  background: var(--background);
} */

/* .unWatch {
  background: var(--background) !important;
} */

.avatarWrap {
  position: relative;
  margin-left: 22px;
}

.avatarWrap > span:nth-child(1) {
  position: absolute;
  z-index: 1;
  right: -3px;
  bottom: -6px;
}

.type {
  border: 1px solid #fff;
}

.thumbnail {
  background: rgba(16, 37, 72, 0.06);
}

.user {
  background-color: var(--success-color);
}
.comment {
  background-color: var(--info-color);
}
.like {
  background-color: var(--error-color);
}

.message {
  max-width: 322px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 145%;
  /* or 23px */

  color: rgba(8, 10, 37, 0.6);
}
